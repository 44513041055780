<template>
  <div class="right-content">
    <!--    <div class="top">
          <div class="cate">
            <div class="item">全部</div>
            <div class="item active">朋友</div>
            <div class="item">活动策划师</div>
            <div class="item">平面设计师</div>
            <div class="item">三维设计师</div>
          </div>
        </div>-->
    <!--  账户设置  -->

    <div class="account-set">
      <el-form :rules="rules" ref="form" size="large" :model="form" @keyup.enter.down="keyDown">
        <el-form-item prop="avatar" class="tx-upload">
          <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccess"
                     :show-file-list=false>
            <div class="tx">
              <el-image class="pic" :src="form.avatar" lazy>
                <template #error>
                  <el-image class="pic" src="/static/upload/tx2.png" lazy></el-image>
                </template>
              </el-image>
              <img src="/static/iconImg/icon-photo.png" alt="" class="icon">
            </div>
          </el-upload>
          <div class="tips">
            <p>请上传正方形头像图片</p>
            <p>支持jpg、png，大小2MB以内</p>
          </div>
        </el-form-item>
        <el-form-item prop="nickname" class="input-item">
          <span class="tit">昵称</span>
          <el-input v-model="form.nickname" type="text" style="width: 240px;" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item prop="gender" class="input-item">
          <span class="tit">性别</span>
          <el-radio-group v-model="form.gender" size="large">
            <el-radio label="男">男</el-radio>
            <el-radio label="女">女</el-radio>
            <el-radio label="保密">保密</el-radio>
          </el-radio-group>

        </el-form-item>
        <el-form-item prop="birth" class="input-item">
          <span class="tit">出生日期</span>
          <el-date-picker v-model="form.birth" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择出生日期" style="width:366px;height:56px;"></el-date-picker>
        </el-form-item>
        <el-form-item prop="profession" class="input-item">
          <span class="tit">职业</span>
          <el-select v-model="form.profession" filterable placeholder="请选择您的职业/身份">
            <el-option v-for="item in profession" :key="item.id" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item prop="reside" class="input-item">
          <span class="tit">城市</span>
          <el-cascader v-model="reside" :options="area" :props="area_props" collapse-tags clearable @change="areaChange" style="width:366px;"></el-cascader>
        </el-form-item>
        <el-form-item prop="workday" class="input-item">
          <span class="tit">工作时间</span>
          <el-date-picker v-model="form.workday" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="请选择参加工作时间" style="width:366px;height:56px;"></el-date-picker>
        </el-form-item>
        <div class="btn-save" @click="submit_info">保存修改</div>
      </el-form>
    </div>
  </div>
</template>
<script>
import pub from "@/api/public";
import {getToken, setAccount} from "@/utils/auth";
import attachment from "@/api/public";
import article from "@/api/article";
import user from "@/api/user";

export default {
  name: "account_info",
  components: {},
  data() {
    return {
      upload_url: '',
      headers: {"Authorization": getToken()},
      loading: false,
      profession: [],
      area:[],
      area_props: { multiple: false,label:'name',value:'name' },
      reside:[],
      form: {
        nickname: '',
        avatar: '',
        gender: '男',
        birth:'',
        resideprovince: '',
        residecity: '',
        residedist: '',
        workday: '',
        profession:''
      },
      rules: {
        nickname: [{required: true, message: '请输入昵称', trigger: 'blur'}, {min: 6,max: 26, message: '昵称长度在 6 到 26 个字符',trigger: 'blur'}],
        code: [{required: true, message: '请输入图片验证码', trigger: 'blur'}, {min: 4,max: 6, message: '验证码长度在 4 到 6 个字符',trigger: 'blur'}]
      }
    }
  },
  created() {
    attachment.uploadFileUrl().then(res => {
      this.upload_url = res;
    });
    this.getUserinfo();
    this.getProfession();
    this.getArea();
  },
  methods: {
    //获取 职业
    async getProfession() {
      await article.getCategoryNameList({
        ident:'profession'
      }).then(res => {
        this.profession = res.data || [];
        this.loading = false;
      })
    },
    //选中地址
    areaChange(area) {
      if(area){
        this.form.resideprovince = area[0];
        this.form.residecity = area[1];
        this.form.residedist = area[2];
      }
    },
    //获取 省市县
    async getArea() {
      await pub.area({
        param:'no'
      }).then(res => {
        this.area = res.data || [];
        this.loading = false;
      })
    },
    handleImgSuccess(res) {
      if (res.code == 1) {
        this.$message.error({
          message: res.msg
        });
      } else {
        this.form.avatar = res.data.url;
        this.$message.success({
          message: '上传文件成功！'
        });
      }
    },
    //获取
    async getUserinfo() {
      await user.getUserinfo({
        param:'no'
      }).then(res => {
        this.form = res.data || [];
        console.log(this.form);
        this.reside=[];
        this.reside.push(this.form.resideprovince);
        this.reside.push(this.form.residecity);
        this.reside.push(this.form.residedist);

        this.loading = false;
      })
    },
    //enter键提交
    keyDown(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.submit_info();
      }
    },
    submit_info() {
      this.loading = true;
      const that = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          user.editUserinfo(this.form).then(res => {
            if (res) {
              setAccount(res.data);
              this.$message.success(res.msg);
              //that.$router.push('/user/home')
            }
            that.loading = false;
          });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.account-set .el-input{height:56px;width:366px !important;}
.account-set .el-select{height:56px;width:366px !important;}
.account-set .el-input__wrapper{height:56px;border-radius: 8px;border:1px solid #ccc;box-shadow: none;}
:deep(.el-select .el-input){height:56px;}
:deep(.el-cascader .el-input){height:56px;}
:deep(.account-set .el-form-item__error){margin-left:138px;}
</style>